import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { NgProgressModule } from 'ngx-progressbar';
import { PLPipesModule } from '@common/pipes';
import { PLCommonModule } from '@root/src/app/common';
import {
  PLDotLoaderModule,
  PLModalModule,
  PLIconModule,
  PLInputModule,
  PLButtonModule,
  PLTabsModule,
  PLTableModule,
  PLTableFrameworkModule,
} from '@root/src/lib-components';

import { PLContactClsmModalComponent } from './assignment-card-modals/pl-contact-clsm-modal.component';
import { AssignmentManagerOrgHeaderComponent } from './assignment-manager-org-header/assignment-manager-org-header.component';
import { DemandItemHeaderComponent } from './demand-item-header/demand-item-header.component';
import { FulfillmentPillComponent } from './fulfillment-pill/fulfillment-pill.component';
import { PlAssignmentAcceptedModalComponent } from './pl-assignment-accepted-modal.component';
import { PlAssignmentManagerDetailsModalComponent } from './pl-assignment-manager-details-modal.component';
import { PLAssignmentManagerReturningDetailsComponent } from './pl-assignment-manager-returning-details.component';
import { PLAssignmentManagerRouteGuardService } from './pl-assignment-manager-routeguard.service';
import { PLAssignmentManagerComponent } from './pl-assignment-manager.component';
import { PLAssignmentManagerService } from './pl-assignment-manager.service';
import { PLAssignmentProposalItemService } from './pl-assignment-proposal-item.service';
import { PLAssignmentsTableComponent } from './pl-assignments-table/pl-assignments-table.component';
import { PLCustomAssignmentModalComponent } from './pl-custom-assignment-modal.component';
import { PLDeclineAssignmentModalComponent } from './pl-decline-assignments-modal.component';
import { PLMissingRequirementsMessageComponent } from './pl-missing-requirements-message/pl-missing-requirements-message.component';
import { PLProviderAssignmentsComponent } from './pl-provider-assignments.component';
import { PLRejectAssignmentModalComponent } from './pl-reject-assignments-modal.component';
import { PLStopAssignmentModalComponent } from './pl-stop-assignments-modal.component';
import { PLUpdateAssignmentErrorModalComponent } from './pl-update-assignment-error-modal.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgProgressModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatTableModule,
    MatFormFieldModule,
    MatInputModule,
    PLButtonModule,
    MatOptionModule,
    MatSelectModule,
    PLCommonModule,
    PLDotLoaderModule,
    PLIconModule,
    PLInputModule,
    PLModalModule,
    PLTabsModule,
    PLTableModule,
    PLTableFrameworkModule,
    ReactiveFormsModule,
    RouterModule,
    MatTooltipModule,
    PLPipesModule,
    MatRadioModule,
    MatDialogModule,
    MatSnackBarModule,
    MatTabsModule,
  ],
  exports: [
    PLProviderAssignmentsComponent,
    AssignmentManagerOrgHeaderComponent,
    FulfillmentPillComponent,
    DemandItemHeaderComponent,
    PlAssignmentManagerDetailsModalComponent,
    PLContactClsmModalComponent,
    PLMissingRequirementsMessageComponent,
    PlAssignmentAcceptedModalComponent,
  ],
  declarations: [
    PLProviderAssignmentsComponent,
    PLAssignmentManagerComponent,
    PLAssignmentsTableComponent,
    PLCustomAssignmentModalComponent,
    PLRejectAssignmentModalComponent,
    PLDeclineAssignmentModalComponent,
    PLStopAssignmentModalComponent,
    PLUpdateAssignmentErrorModalComponent,
    PLAssignmentManagerReturningDetailsComponent,
    AssignmentManagerOrgHeaderComponent,
    FulfillmentPillComponent,
    DemandItemHeaderComponent,
    PlAssignmentManagerDetailsModalComponent,
    PLContactClsmModalComponent,
    PLMissingRequirementsMessageComponent,
    PlAssignmentAcceptedModalComponent,
  ],
  providers: [
    PLAssignmentManagerRouteGuardService,
    PLAssignmentManagerService,
    PLAssignmentProposalItemService,
  ],
})
export class PLAssignmentManagerModule {}
