<div class="modal-content">
  <div class="flex flex-col">
    <div class="flex w-full">
      <h2>Assignment accepted!</h2>
    </div>
    <div class="details">
      <h3 class="organization">{{ demandOrganization }}</h3>
      <div class="service-line">{{ demandServiceLine }}</div>
      <div class="w-full content">
        You have successfully accepted this assignment. <br />
        It can be found under your "My Assignments" tab.
      </div>
    </div>
    <div class="flex w-full gap-24 justify-end">
      <button
        mat-flat-button
        color="primary"
        class="save-basic-info-button"
        type="submit"
        (click)="onOk()"
      >
        Ok
      </button>
    </div>
  </div>
</div>
