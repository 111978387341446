import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import * as Sentry from '@sentry/angular-ivy';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

let traceSampleRate = 1.0;
if (environment.production) {
  enableProdMode();
  traceSampleRate = 0.25;
}

Sentry.init({
  dsn: environment.sentry_key,
  environment: environment.env_key,
  release: environment.git_sha,
  integrations: [Sentry.browserTracingIntegration()],
  tracesSampleRate: traceSampleRate,
  ignoreErrors: [
    // use string or regex.use string for 'partial matches
    'ResizeObserver loop limit exceeded', // see https://stackoverflow.com/a/50387233/560585
    'ResizeObserver loop completed with undelivered notifications', // https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserver#observation_errors
    'Non-Error promise rejection captured with keys: isTrusted', // PL-2472
  ],
});

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
