declare var _envFileConfig: any;

import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Store } from '@ngrx/store';

import { environment } from '@environments/environment';
import {
  PLUrlsService,
  PLHttpErrorService,
  PLClientStudentDisplayService,
} from '@root/index';
import { AppStore } from './appstore.model';
import { User } from './modules/user/user.model';

@Injectable()
export class AppConfigService {
  apps: any = environment.apps;

  showAppNav = true;
  currentUser: User;
  genericErrorMessage =
    'Oops, something went wrong. Please try again. If the problem persists, please contact us for support.';
  zendeskSupportLinks = false;

  constructor(
    private plUrls: PLUrlsService,
    private plHttpError: PLHttpErrorService,
    private router: Router,
    private store: Store<AppStore>,
  ) {
    this.store.select('currentUser').subscribe((user: any) => {
      this.currentUser = user;
      this.setErrorMessages();
    });
    this.store.select('featureFlags').subscribe((lookup: any) => {
      this.zendeskSupportLinks = lookup.flags.zendeskSupportLinks;
      if (this.zendeskSupportLinks) {
        this.formUrls();
      }
    });

    this.checkForConfig();
    this.formUrls(this.apps);

    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationStart) {
        this.showAppNav = true;
      }
    });
  }

  checkForConfig() {
    if (typeof _envFileConfig !== 'undefined' && _envFileConfig.apps) {
      this.apps = Object.assign(this.apps, _envFileConfig.apps);
      console.log('Using envConfigFile, apps:', this.apps);
      this.formUrls(this.apps);
    }
  }

  formUrls(apps1: any = null) {
    let urls = {};
    if (!apps1) {
      apps1 = this.apps;
    }
    if (apps1) {
      let apps = [
        'auth',
        'apiWorkplace',
        'mizar',
        'platform',
        'room',
        'library',
        'therapy',
        'edgeGateway',
        'chiron',
      ];
      const appUrls: any = {};
      apps.forEach((app: any) => {
        appUrls[app] = apps1[app] && apps1[app].url ? apps1[app].url : '';
      });

      urls = {
        auth: appUrls.auth,
        changePasswordFE: `${environment.login_url}/password/change`,
        assumeLogin: `${environment.login_url}/hijack/email/`,
        login: `${environment.login_url}/login/`,
        logout: `${environment.login_url}/logout/`,
        releaseLogin: `${environment.login_url}/hijack/release-hijack/`,
        status: `${environment.login_url}/api/v1/status/`,
        learnuponSAML: `${environment.login_url}/saml/sso/learnupon`,
        accounts: `${appUrls.auth}/api/v1/accounts/`,
        assignments: `${appUrls.auth}/api/v1/assignments/`,
        user: `${appUrls.auth}/api/v1/user/`,
        users: `${appUrls.auth}/api/v2/users/`,
        permissions: `${appUrls.auth}/api/v1/permissions/`,
        gatewayZendeskTokenCreation: `${appUrls.edgeGateway}/auth/token/zendesk`,

        apiWorkplace: appUrls.apiWorkplace,
        apollo: `${appUrls.apiWorkplace}/graphql/v1/`,
        accountDocuments: `${appUrls.apiWorkplace}/api/v3/accountdocuments/`,
        accountDocumentTypes: `${appUrls.apiWorkplace}/api/v3/accountdocumentstype/`,
        activities: `${appUrls.apiWorkplace}/api/v1/activities/`,
        agreements: `${appUrls.apiWorkplace}/api/v1/agreements/`,
        appointments: `${appUrls.apiWorkplace}/api/v3/appointments/`,
        assignmentProposals: `${appUrls.apiWorkplace}/api/v1/assignmentproposals/`,
        assignmentsMarketplace: `${appUrls.apiWorkplace}/api/v1/provider-demands/`,
        assignmentPreferences: `${appUrls.apiWorkplace}/api/v1/assignment-preferences/`,
        availabilityPreferenceV2: `${appUrls.apiWorkplace}/api/v2/provider-availability/`,
        submittedAssignmentPreferences: `${appUrls.apiWorkplace}/api/v1/assignment-preferences/submitted`,
        areasOfConcern: `${appUrls.apiWorkplace}/api/v1/areas-of-concern/`,
        assessments: `${appUrls.apiWorkplace}/api/v1/assessments/`,
        availabilitySettings: `${appUrls.apiWorkplace}/api/v1/availability/preferences/`,
        billingCodes: `${appUrls.apiWorkplace}/api/v1/billing_codes/`,
        caseloadSeverityLevelPreferences: `${appUrls.apiWorkplace}/api/v1/provider-caseload-severity-levels/`,
        clients: `${appUrls.apiWorkplace}/api/v1/clients/`,
        clientServices: `${appUrls.apiWorkplace}/api/v2/client-services/`,
        contactTypes: `${appUrls.apiWorkplace}/api/v1/contact-types/`,
        contacts: `${appUrls.apiWorkplace}/api/v1/contacts/`,
        dateState: `${appUrls.apiWorkplace}/api/v1/date-state/`,
        demand: `${appUrls.apiWorkplace}/api/v1/demand/`,
        demandNotes: `${appUrls.apiWorkplace}/api/v1/demand/notes`,
        demandView: `${appUrls.apiWorkplace}/api/v1/demand/view`,
        organizationServiceModel: `${appUrls.apiWorkplace}/api/v1/organizations/servicemodel`,
        directServices: `${appUrls.apiWorkplace}/api/v1/direct-services/`,
        documentTypes: `${appUrls.apiWorkplace}/api/v1/document-types/`,
        documents: `${appUrls.apiWorkplace}/api/v1/documents/`,
        educationLevelPreferences: `${appUrls.apiWorkplace}/api/v1/provider-education-levels/`,
        ethnicities: `${appUrls.apiWorkplace}/api/v1/ethnicities/`,
        evaluations: `${appUrls.apiWorkplace}/api/v3/evaluations/`,
        evaluationActivities: `${appUrls.apiWorkplace}/api/v3/evaluations/:evaluation_uuid/activities/`,
        events: `${appUrls.apiWorkplace}/api/v2/events/`,
        handbookSection: `${appUrls.apiWorkplace}/api/v1/handbooksection/`,
        iepSystems: `${appUrls.apiWorkplace}/api/v1/iep-systems/`,
        isas: `${appUrls.apiWorkplace}/api/v1/isa-info/`,
        invoices: `${appUrls.apiWorkplace}/api/v3/invoices/`,
        invoicesPreview: `${appUrls.apiWorkplace}/api/v3/invoices/preview/`,
        jumbotron: `${appUrls.apiWorkplace}/api/v1/jumbotron/`,
        jumbotronItems: `${appUrls.apiWorkplace}/api/v1/jumbotron/:jumbotron_uuid/items/`,
        languages: `${appUrls.apiWorkplace}/api/v1/languages/`,
        locations: `${appUrls.apiWorkplace}/api/v1/locations/`,
        locationMentionableUsers: `${appUrls.apiWorkplace}/api/v1/locations/:location_uuid/mentionable_users/`,
        medicaidSystems: `${appUrls.apiWorkplace}/api/v1/medicaid-systems/`,
        metrics: `${appUrls.apiWorkplace}/api/v1/metrics/`,
        metricsPoints: `${appUrls.apiWorkplace}/api/v1/metrics-points/`,
        notesSchemas: `${appUrls.apiWorkplace}/api/v1/notes/schemas/`,
        notesExports: `${appUrls.apiWorkplace}/api/v1/notes/exports/`,
        notesExportsOrganizations: `${appUrls.apiWorkplace}/api/v1/notes/exports/organizations/`,
        opportunitys: `${appUrls.apiWorkplace}/api/v1/opportunitys/`,
        organizations: `${appUrls.apiWorkplace}/api/v1/organizations/`,
        preagreements: `${appUrls.apiWorkplace}/api/v1/preagreements/`,
        preagreementW2s: `${appUrls.apiWorkplace}/api/v1/preagreementw2s/`,
        preagreementW2sList: `${appUrls.apiWorkplace}/api/v1/preagreementw2slist/`,
        preagreementpaygrid: `${appUrls.apiWorkplace}/api/v1/preagreementpaygrid/`,
        applicantPaygrid: `${appUrls.apiWorkplace}/api/v1/applicant-paygrid/`,
        providerrategrid: `${appUrls.apiWorkplace}/api/v1/providerrategrid/`,
        pastproviderrategrid: `${appUrls.apiWorkplace}/api/v1/pastproviderrategrid/`,
        privatePractice: `${appUrls.apiWorkplace}/api/v1/private-practice/`,
        providerTypes: `${appUrls.apiWorkplace}/api/v1/provider-types/`,
        providers: `${appUrls.apiWorkplace}/api/v1/providers/`,
        providerSpecialties: `${appUrls.apiWorkplace}/api/v1/provider-specialties/`,
        providerPool: `${appUrls.apiWorkplace}/api/v1/provider-pool`,
        providerPoolV2: `${appUrls.apiWorkplace}/api/v2/provider-pool`,
        providerAgreements: `${appUrls.apiWorkplace}/api/v1/provider-agreements/`,
        fountainProviderSignedAgreements: `${appUrls.apiWorkplace}/api/v1/signed-provider-agreements/`,
        races: `${appUrls.apiWorkplace}/api/v1/races/`,
        records: `${appUrls.apiWorkplace}/api/v1/records/`,
        recording: `${appUrls.platform}/api/v3/recording/`,
        referralNotes: `${appUrls.apiWorkplace}/api/v1/referrals/:referral_uuid/notes/`,
        schoolTypePreferences: `${appUrls.apiWorkplace}/api/v1/provider-school-types/`,
        sisSystems: `${appUrls.apiWorkplace}/api/v1/sis-systems/`,
        services: `${appUrls.apiWorkplace}/api/v1/services/`,
        serviceTypes: `${appUrls.apiWorkplace}/api/v1/service-types/`,
        timesheet: `${appUrls.apiWorkplace}/api/v2/timesheet/`,
        timesheetAmendments: `${appUrls.apiWorkplace}/api/v1/timesheet-amendments/`,
        upload: `${appUrls.apiWorkplace}/api/v1/upload/`,
        qualifications: `${appUrls.apiWorkplace}/api/v1/sfproviderqualifications/`,
        workplacePermissions: `${appUrls.apiWorkplace}/api/v1/permissions/`,
        zoomMeetings: `${appUrls.apiWorkplace}/api/v1/zoom/meetings/`,
        zoomMeetingRegistrations: `${appUrls.apiWorkplace}/api/v1/zoom/meetings/registrations/`,
        downloadCalendar: `${appUrls.apiWorkplace}/api/v3/calendars/`,
        providerRateGridDownload: `${appUrls.apiWorkplace}/api/v1/providerrategrid-download/`,
        preagreementRateGridDownload: `${appUrls.apiWorkplace}/api/v1/preagreementrategrid-download/`,
        applicantRateGridDownload: `${appUrls.apiWorkplace}/api/v1/applicant-rate-grid-download/`,

        mizar: appUrls.mizar,
        uploadImage: `${appUrls.mizar}/v1/images/`,
        getImage: `${appUrls.mizar}/v1/images/`,
        deleteImage: `${appUrls.mizar}/v1/images/`,

        platform: appUrls.platform,
        room: `${appUrls.platform}/api/v1/room/`,
        roomResetWhiteboard: `${appUrls.platform}/api/v1/room/reset_whiteboard/`,

        summarize: `${appUrls.chiron}/v1/summarize`,

        roomFE: appUrls.room,
        techcheckFE: `${appUrls.room}/pl/setup/`,

        libraryFE: appUrls.library,

        scheduleFE: `${window.location.origin}/c/schedule`,

        kangaFE: appUrls.therapy,

        helpDocsFE: this.zendeskSupportLinks
          ? 'http://support.presence.com/'
          : 'https://presencelearning.helpjuice.com',
        copyrightFE: `https://www.presencelearning.com/about/copyright-policy/`,
        codeOfConductFE: `https://www.presencelearning.com/about/code-of-conduct/`,
        privacyPolicyFE: 'https://presence.com/about/privacy-policy/',
      };
    }

    this.plUrls.setUrlsDefaults(urls);
    this.plUrls.formUrls();
  }

  setErrorMessages() {
    const clientStudentText = PLClientStudentDisplayService.get(
      this.currentUser,
    );
    const messages = {
      clients: [
        {
          code: 401,
          msg: `You do not have access to these ${clientStudentText}s`,
        },
      ],
      'evaluations/:uuid': [
        {
          code: 400,
          status:
            "The evaluation can't be completed because it has appointments in the future",
          msg: "The evaluation can't be completed because it has appointments in the future",
        },
      ],
      'invoices/:uuid/retract': [
        {
          code: 403,
          msg: 'This invoice has already been processed and cannot be retracted.',
        },
      ],
      'clients/:uuid/providers': (err: any) => {
        if (err && err.error && err.error.length) {
          return '* ' + err.error[0];
        }
        return this.genericErrorMessage;
      },
    };
    this.plHttpError.setMessages(messages);
  }
}
